import { useWeb3React } from "@web3-react/core";
import React from "react";
import Web3 from "web3";
import { injected } from "../utils/connectors";
import connectWalletImage from "../images/connect_wallet.png";
import styled from "styled-components";

export const ButtomImage = styled.img`
  display: block;
  --max-height: 550px;
  /* Set a baseline width for your element */
  width: 100%;
  /* And limit it with our function above */
  max-width: calc(var(--max-height, 200px) * var(--img-ratio, 1));
  cursor: pointer;
`;

const ConnectWallet: React.FC = () => {
  const { activate } = useWeb3React<Web3>();

  return (
    <ButtomImage
      src={connectWalletImage}
      alt="Connect Wallet"
      onClick={() => activate(injected)}
    />
  );
};

export default ConnectWallet;

import { Web3ReactProvider } from "@web3-react/core";
import { ButtomImage } from "./components/ConnectWallet";
import getLibrary from "./utils/getLibrary";
import logo from "./images/logo.png";

import styled from "styled-components";
import Mint from "./components/Mint";

const Container = styled.div`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* margin: auto; */
  color: white;
  padding: 0px 20px 0px 20px;
`;

function App() {
  return (
    <Web3ReactProvider getLibrary={getLibrary}>
      <Container>
        <ButtomImage
          src={logo}
          alt="CriptoMentor NFT"
          // height={"231px"}
          // width={"153px"}
        />

        <p>
          To mint your NFT click on Connect Wallet button them click on Mint
          button
        </p>
        <Mint />
      </Container>
    </Web3ReactProvider>
  );
}

export default App;

import { useWeb3React } from "@web3-react/core";
import React, { useCallback, useState } from "react";
import mintImage from "../images/mint.png";
import Web3 from "web3";
import ConnectWallet, { ButtomImage } from "./ConnectWallet";
import { usePreSale } from "../hooks/usePreSale";
import styled from "styled-components";
import BN from "bignumber.js";

const Column = styled.div`
  display: flex;
  flex-direction: column;
`;

const Mint: React.FC = () => {
  const { account } = useWeb3React<Web3>();
  const preSale = usePreSale();
  const [hash, setHash] = useState<string>();
  const [amountToMint, setAmountToMint] = useState(1);

  const mint = useCallback(() => {
    const mintValue = new BN(0.2)
      .multipliedBy(amountToMint)
      .multipliedBy(10 ** 18)
      .toString();

    // console.log(`valor do mint ${mintValue}, from ${account}`);
    // console.log(preSale);
    preSale?.methods.buy(amountToMint).send(
      {
        from: account,
        value: mintValue,
      },
      function (err: any, transactionHash: string) {
        if (err) console.error(err);
        else setHash(transactionHash);
      }
    );
  }, [account, amountToMint, preSale]);

  if (account && !hash)
    return (
      <Column>
        <ButtomImage src={mintImage} alt="Mint" onClick={mint} />
        <select
          name="amountToMint"
          id="amountToMint"
          onChange={({ target }) => setAmountToMint(Number(target.value))}
        >
          <option value={1}>1</option>
          <option value={2}>2</option>
        </select>
      </Column>
    );

  if (hash) return <p>{hash}</p>;

  return <ConnectWallet />;
};

export default Mint;

import { useWeb3React } from "@web3-react/core";
import Web3 from "web3";
import { abi } from "../static/abis/NFTPreSale.json";
import { AbiItem } from "web3-utils";
import { PRESALE_ADDRESSES } from "../constants/PRESALE_ADDRESSES";
import { useMemo } from "react";

export const usePreSale = () => {
  const { library, chainId } = useWeb3React<Web3>();

  return useMemo(() => {
    const contractAddress = PRESALE_ADDRESSES[chainId ?? 0];

    if (contractAddress) {
      return new library!.eth.Contract(abi as AbiItem[], contractAddress);
    }
  }, [chainId, library]);
};
